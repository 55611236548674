<template>
  <div class="row">
    <div class="col-md-7">
      <div class="card m-0">
        <div v-if="$store.getters.can('hidrocarburos.contratos.edit')" class="card-header p-1 bg-light">
          <div class="row justify-content-between">
            <div class="col-md-2">
              <label class="badge text-md mr-2 mb-0 align-top" :class="accion_ruta ? 'bg-frontera-top-left text-white' : 'bg-gray'">
                <div class="custom-control custom-checkbox">
                  <input v-model="accion_ruta"
                    type="checkbox" id="accion_ruta" 
                    class="custom-control-input" 
                    checked="" 
                  >
                  <label for="accion_ruta" class="custom-control-label">
                    <i class="fas fa-edit text-xl p-1"></i>
                  </label>
                </div>
                <div class="text-center">
                  <span class="badge" :class="accion_ruta ? 'bg-sos-green text-white' : 'bg-dark'">
                    Editar Ruta
                  </span>
                </div>
              </label>
            </div>
            <div class="col-md-6" v-if="accion_ruta">
              <label>Mejorar Ruta:</label>
              <gmap-autocomplete
                class="form-control form-control-sm"
                @place_changed="setPlaceWaypoint"
                :options="{
                  fields: ['geometry', 'formatted_address', 'address_components'],
                }"
                :value="addressInput"
              >
                >
                <template v-slot:input="slotProps">
                  <v-text-field
                    outlined
                    prepend-inner-icon="place"
                    placeholder="Location Of Event"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                  >
                  </v-text-field>
                </template>
              </gmap-autocomplete>
            </div>
            <!-- <div class="col-md-2" v-if="accion_ruta">
              <label class="badge text-md mr-2 mb-0 align-top" :class="accion_coordenadas ? 'bg-frontera-top-left text-white' : 'bg-gray'">
                <div class="custom-control custom-checkbox">
                  <input v-model="accion_coordenadas"
                    @change="resetCoors"
                    type="checkbox" id="accion_coordenadas" 
                    class="custom-control-input" 
                    checked="" 
                  >
                  <label for="accion_coordenadas" class="custom-control-label">
                    <i class="fas fa-compass text-xl p-1"></i>
                  </label>
                </div>
                <div class="text-center">
                  <span class="badge" :class="accion_coordenadas ? 'bg-sos-green text-white' : 'bg-dark'">
                    Coordenadas
                  </span>
                </div>
              </label>
            </div>
            <div class="col-md-3" v-if="accion_ruta">
              <label class="mb-0">Buscar Origen:</label>
              <gmap-autocomplete v-if="!accion_coordenadas"
                class="form-control form-control-sm"
                @place_changed="setPlaceOrigen"
                :options="{
                  fields: ['geometry', 'formatted_address', 'address_components'],
                }"
                :value="addressInput"
              >
                >
                <template v-slot:input="slotProps">
                  <v-text-field
                    outlined
                    prepend-inner-icon="place"
                    placeholder="Location Of Event"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                  >
                  </v-text-field>
                </template>
              </gmap-autocomplete>
              <div v-else>
                <input v-model="coords.lat_origen" 
                  @blur="setCoordOrigen"
                  type="text" 
                  placeholder="Latitud" 
                  class="form-control form-control-sm col-12 mb-1"
                >
                <input v-model="coords.lng_origen" 
                  @blur="setCoordOrigen"
                  type="text" 
                  placeholder="Longitud" 
                  class="form-control form-control-sm col-12 mb-1"
                >
              </div>
            </div>
            <div class="col-md-3" v-if="accion_ruta && markers.ruta.path.length > 0">
              <label class="mb-0">Buscar Destino:</label>
              <gmap-autocomplete v-if="!accion_coordenadas"
                class="form-control form-control-sm"
                @place_changed="setPlaceDestino"
                :options="{
                  fields: ['geometry', 'formatted_address', 'address_components'],
                }"
                :value="addressInput"
              >
                >
                <template v-slot:input="slotProps">
                  <v-text-field
                    outlined
                    prepend-inner-icon="place"
                    placeholder="Location Of Event"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                  >
                  </v-text-field>
                </template>
              </gmap-autocomplete>
              <div v-else>
                <input v-model="coords.lat_destino" 
                  @blur="setCoordDestino"
                  type="text" 
                  placeholder="Latitud" 
                  class="form-control form-control-sm col-12 mb-1"
                >
                <input v-model="coords.lng_destino" 
                  @blur="setCoordDestino"
                  type="text" 
                  placeholder="Longitud" 
                  class="form-control form-control-sm col-12 mb-1"
                >
              </div>
            </div> -->
            <div class="col-md-1 text-center" v-if="accion_ruta && markers.ruta.path.length > 1">
              <button @click="save()"
                type="button"
                class="btn btn-sm btn-success mt-4"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
        </div>
        <div v-if="markers.waypoints.length > 0" class="card-body p-0 pt-2">
          <h5 class="ml-3">Referencias de Ruta</h5>
          <table class="table table-bordered text-nowrap table-responsive table-sm mb-2">
            <thead class="bg-gray-dark">
              <tr>
                <th>Referencia</th>
                <th class="col-3">Latitud</th>
                <th class="col-3">Longitud</th>
                <th class="col-1">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(wp, index) in markers.waypoints"
                :key="index"
              >
                <td><small>Referencia {{ index + 1 }}</small></td>
                <td><small>{{ wp.location.lat }}</small></td>
                <td><small>{{ wp.location.lng }}</small></td>
                <td class="text-right">
                  <div class="btn-group">
                    <button type="button" class="btn btn-xs bg-danger"
                      @click="removeWaypoint(index)"
                      :disabled="!accion_ruta"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body p-0">
          <gmap-map
            style="width: 100%; height: 500px"
            id="mapSitiosInteres"
            :center="viewMap.center"
            :zoom="viewMap.zoom"
            :options="{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeControl: true,
            }"
          >
            <!-- Popup de informaciones -->
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <div v-html="infoContent"></div>
            </gmap-info-window>
            <!-- Polilíneas Ruta -->
            <div v-if="ruta && ruta.ruta_polilineas">
              <!-- Recorrido ruta -->
              <gmap-polyline
                v-if="markers.ruta.path.length > 0"
                :options="markers.ruta.options"
                :path="markers.ruta.path"
                :editable="false"
              >
              </gmap-polyline>
              <!-- Inicio de ruta -->
              <gmap-marker
                v-if="markers.inicio_ruta"
                :position="markers.inicio_ruta.position"
                :icon="url_docs + '/' + markers.inicio_ruta.icon"
              ></gmap-marker>
              <!-- Fin de ruta -->
              <gmap-marker
                v-if="markers.fin_ruta"
                :position="markers.fin_ruta.position"
                :icon="url_docs + '/' + markers.fin_ruta.icon"
              ></gmap-marker>
            </div>
            <!-- Iconos waypoints -->
            <div v-if="accion_ruta">
              <gmap-marker
                :key="index"
                v-for="(way, index) in markers.waypoints"
                :position="way.location"
                :icon="url_docs + '/image/iconos/waypoint.png'"
                :clickable="true"
                @click="(viewMap.center = way.location), toggleInfoWindow(way)"
                :draggable="true"
                @dragend="editWaypoint($event, index)"
              ></gmap-marker>
            </div>
            <!-- Polilíneas Edicion de Velocidades en un tramo -->
            <gmap-polyline v-if="markers.edit_max_vel.length > 0"
              :options="{
                strokeColor: '#b0c442',
                strokeOpacity: '0.9',
                strokeWeight: 5,
              }"
              :path="markers.edit_max_vel"
            >
            </gmap-polyline>
            <!-- Inicio de tramo editable -->
            <gmap-marker v-if="this.array_vel.length > 0"
              :position="{
                lat: parseFloat(this.array_vel[0].lat_ini),
                lng: parseFloat(this.array_vel[0].lon_ini),
              }"
              :icon="url_docs + '/' + tipo_sitio_img"
            ></gmap-marker>
            <!-- Fin de tramo editable -->
            <gmap-marker v-if="this.array_vel.length > 1"
              :position="{
                lat: parseFloat(this.array_vel[this.array_vel.length - 1].lat_fin),
                lng: parseFloat(this.array_vel[this.array_vel.length - 1].lon_fin),
              }"
              :icon="url_docs + '/' + tipo_sitio_img"
            ></gmap-marker>
          </gmap-map>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="card m-0">
        <div class="card-header bg-light p-1">
          <label v-if="ruta && ruta.ruta_polilineas.length > 0" class="badge bg-frontera-top-left text-white text-md mr-2 mb-0 text-center align-top"
            data-toggle="modal"
            data-target="#modal_indicaciones_ruta"
          >
            <div>
              <i class="fas fa-map-marked-alt text-xl p-1"></i>
            </div>
            <span class="badge bg-sos-green">Indicaciones</span>
          </label>
          <label v-if="!$v.form_vel.$invalid && array_vel.length > 1" class="badge bg-frontera-top-left text-white text-md mr-2 mb-0 text-center align-top"
            data-toggle="modal"
            data-target="#modal_editar_km_max"
          >
            <div>
              <i class="fas fa-tachometer-alt text-xl p-1"></i>
            </div>
            <span class="badge bg-sos-green">Actualizar Velocidades</span>
          </label>
        </div>
        <div v-if="ruta && ruta.ruta_polilineas.length > 0" class="card-body table-responsive p-0">
          <table class="table table-bordered text-nowrap table-responsive table-sm" style="height: 500px;">
            <thead class="bg-gray-dark">
              <tr>
                <th class="col-1" rowspan="2">Orden</th>
                <th class="col-1" rowspan="2">Acción</th>
                <th class="col-1" rowspan="2">Vel max</th>
                <th class="col-6" rowspan="2">Observación</th>
                <th class="col-5" colspan="2">Posición Inicial</th>
                <th class="col-5" colspan="2">Posición Final</th>
              </tr>
              <tr class="bg-teal text-center">
                <th>Latitud</th>
                <th>Longitud</th>
                <th>Latitud</th>
                <th>Longitud</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rut, index) in ruta.ruta_polilineas" :key="rut.id">
                <td class="text-center">
                  <span class="badge bg-lightblue">{{ rut.orden }}</span>
                </td>
                <td class="text-center">
                  <div class="custom-control custom-checkbox">
                    <input :id="`checkboxVel${index}`" 
                      @click="getTramoMaxVel(index, rut)"
                      class="custom-control-input check-tramos" 
                      type="checkbox"
                      :disabled="array_vel.length > 1 && !array_vel.find(tra => tra.orden === rut.orden)"
                      >
                    <label :for="`checkboxVel${index}`" class="custom-control-label"></label>
                  </div>
                </td>
                <td class="text-center">
                  <div class="btn-group">
                    <button type="button" class="btn btn-xs bg-primary"
                      data-toggle="modal"
                      data-target="#modal_editar_km_max"
                      @click="getDataFormVelMax(rut)"
                    >
                      {{ rut.km_max }}
                    </button>
                  </div>
                </td>
                <td><small>{{ rut.observacion }}</small></td>
                <td><small>{{ rut.lat_ini }}</small></td>
                <td><small>{{ rut.lon_ini }}</small></td>
                <td><small>{{ rut.lat_fin }}</small></td>
                <td><small>{{ rut.lon_fin }}</small></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal_indicaciones_ruta">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Indicaciones de Ruta</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <PanelIndicacionesRuta ref="PanelIndicacionesRuta"></PanelIndicacionesRuta>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal_editar_km_max">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Indicaciones de Ruta</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 form-group">
                <label>Velocidad Máxima:</label>
                <input v-model="form_vel.km_max"
                  type="number"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_vel.km_max.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="col-md-12">
                <label>Observación:</label>
                <input v-model="form_vel.observacion"
                  type="text"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div v-if="!$v.form_vel.$invalid" class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveMaxVel()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import PanelIndicacionesRuta from "../../../../components/PanelIndicacionesRuta";
const google = window.google


export default {
  name: "RutaDetalle",

  components: {
    PanelIndicacionesRuta,
  },

  data() {
    return {
      ruta: null,
      tipo_sitio_img: "image/iconos/default.png",
      inicio_ruta: "image/iconos/inicio_ruta.png",
      fin_ruta: "image/iconos/fin_ruta.png",
      latitud_ini: 4.651371,
      longitud_ini: -74.071509,
      form: {},
      form_vel: {},
      array_vel: [],
      coords: {
        lat_origen: null,
        lng_origen: null,
        lat_destino: null,
        lng_destino: null,
      },
      viewMap: {
        zoom: 12,
        center: {
          lat: 4.651371,
          lng: -74.071509,
        },
      },
      markers: {
        geoCir: [],
        geoPoli: [],
        inicio_ruta: null,
        fin_ruta: null,
        ruta: {
          path: [],
          options: {
            strokeColor: "#d81b60",
            strokeOpacity: "0.7",
            strokeWeight: 8,
          },
        },
        waypoints: [],
        edit_max_vel: [],
      },
      accion_ruta: false,
      accion_coordenadas: false,
      // Datos para el cuadro de información del mapa
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      addressInput: "",
      url_docs: process.env.VUE_APP_API_URL,
    };
  },

  validations: {
    form_vel: {
      id: {
        required,
      },
      km_max: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      this.resetDatos();
      this.tipo_sitio_img = "image/iconos/default.png";
      this.ruta = this.$parent.form ? this.$parent.form : null;
      this.markers.waypoints = this.ruta.waypoints ? JSON.parse(this.ruta.waypoints) : [];
      this.getMarkersRuta();
      this.getPanelRuta();
    },

    resetDatos(){
      this.ruta = null;
      this.form = {};
      this.array_vel = [];
      let reset = document.getElementsByClassName("check-tramos");
      reset.forEach(function(checkbox) { checkbox.checked = false; });

      this.viewMap = {
        zoom: 12,
        center: {
          lat: 4.651371,
          lng: -74.071509,
        },
      };

      this.markers = {
        geoCir: [],
        geoPoli: [],
        inicio_ruta: null,
        fin_ruta: null,
        ruta: {
          path: [],
          options: {
            strokeColor: "#d81b60",
            strokeOpacity: "0.7",
            strokeWeight: 8,
          },
        },
        waypoints: [],
        edit_max_vel: [],
      };
    },

    getDataFormVelMax(punto_ruta){
      this.markers.edit_max_vel = [];
      this.array_vel = []
      let reset = document.getElementsByClassName("check-tramos");
      reset.forEach(function(checkbox) { checkbox.checked = false; });
      this.form_vel = {
        id: [punto_ruta.id],
        km_max: punto_ruta.km_max,
        observacion: punto_ruta.observacion,
      };
    },

    getTramoMaxVel(index, tramo){
      this.form_vel = {};
      this.markers.edit_max_vel = [];
      const check = document.getElementById(`checkboxVel${index}`);
      if (check.checked) {
        this.array_vel.push(this.ruta.ruta_polilineas[index]);
      } else {
        const indice_eliminar = this.array_vel.findIndex(punto => punto.orden === tramo.orden);
        this.array_vel.splice(indice_eliminar, 1)
      }

      // Se pinta el tramo a modificar la velocidad
      if (this.array_vel.length > 1) {
        this.array_vel.sort(function(a, b) { return a.orden - b.orden; });
        const punto_inicial = this.ruta.ruta_polilineas.findIndex(rut => rut.orden === this.array_vel[0].orden);
        const punto_final = this.ruta.ruta_polilineas.findIndex(rut => rut.orden === this.array_vel[1].orden);
        let velocidad_maxima = 60;
        let observacion = "";
        let det_ruta_id = [];
        for (let i = punto_inicial; i <= punto_final; i++) {
          const pos = this.ruta.ruta_polilineas[i];
          det_ruta_id.push(pos.id);
          if (i == punto_inicial) {
            velocidad_maxima = pos.km_max; 
            observacion = pos.observacion; 
            this.markers.edit_max_vel.push({
              lat: parseFloat(pos.lat_ini),
              lng: parseFloat(pos.lon_ini),
            });
          }
          this.markers.edit_max_vel.push({
            lat: parseFloat(pos.lat_fin),
            lng: parseFloat(pos.lon_fin),
          });
        }

        this.form_vel = {
          id: det_ruta_id,
          km_max: velocidad_maxima,
          observacion,
        }
      }
    },

    toggleInfoWindow: function (data) {
      this.infoWindowPos = data.position;
      this.infoContent = data.html;

      //compruebe si es el mismo marcador que se seleccionó en caso afirmativo alternar
      if (this.currentMidx == data.id) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si es un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = data.id;
      }
    },

    getMarkersRuta(){
      this.markers.inicio_ruta = null;
      this.markers.fin_ruta = null;

      if(this.ruta.ruta_polilineas.length > 0){
        // Se establece la posicion de los iconos de inicio y fin de la ruta 
        this.markers.inicio_ruta = {
          icon: this.inicio_ruta,
          position: {
            lat: parseFloat(this.ruta.ruta_polilineas[0].lat_ini),
            lng: parseFloat(this.ruta.ruta_polilineas[0].lon_ini),
          },
        };
        this.markers.fin_ruta = {
          icon: this.fin_ruta,
          position: {
            lat: parseFloat(this.ruta.ruta_polilineas[this.ruta.ruta_polilineas.length - 1].lat_fin),
            lng: parseFloat(this.ruta.ruta_polilineas[this.ruta.ruta_polilineas.length - 1].lon_fin),
          },
        };

        // Se genera los puntos de la ruta a mostrar
        this.markers.ruta.path = [];
        this.ruta.ruta_polilineas.forEach((det) => {
          if (det.orden == 1) {
            this.markers.ruta.path.push({
              lat: parseFloat(det.lat_ini),
              lng: parseFloat(det.lon_ini),
            });
          }
          this.markers.ruta.path.push({
            lat: parseFloat(det.lat_fin),
            lng: parseFloat(det.lon_fin),
          });
        });
      }
      this.centrarMapa();
    },

    getPanelRuta(){
      let ruta = [];
      // creo el arreglo de coordenadas
      this.ruta.ruta_polilineas.forEach((det) => {
        if (det.orden == 1) {
          ruta.push({
            lat: parseFloat(det.lat_ini),
            lng: parseFloat(det.lon_ini),
          });
        }
        ruta.push({
          lat: parseFloat(det.lat_fin),
          lng: parseFloat(det.lon_fin),
        });
      });
      this.$refs.PanelIndicacionesRuta.getIndex(ruta, this.ruta.waypoints);
    },

    // Funciones para el manejo general del mapa
    centrarMapa(){
      if (this.markers.inicio_ruta && this.markers.fin_ruta) {
        const inicio = this.markers.inicio_ruta.position;
        const fin = this.markers.fin_ruta.position;
        this.viewMap.center = {
          lat: (inicio.lat + fin.lat) / 2,
          lng: (inicio.lng + fin.lng) / 2,
        }
        const distancia = this.calcularDistancia(inicio, fin);
        this.viewMap.zoom = this.calcularZoomMapa(distancia);
      } else if (this.markers.inicio_ruta) {
        const inicio = this.markers.inicio_ruta.position;
        this.viewMap.center = {
          lat: inicio.lat,
          lng: inicio.lng,
        }
      } else if (this.markers.fin_ruta) {
        const fin = this.markers.fin_ruta.position;
        this.viewMap.center = {
          lat: fin.lat,
          lng: fin.lng,
        }
      } else {
        this.viewMap.center = {
          lat: this.latitud_ini,
          lng: this.longitud_ini,
        }
      }
    },

    calcularDistancia(origin, destination) {
      return google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(origin.lat, origin.lng),
        new google.maps.LatLng(destination.lat, destination.lng)
      );
    },

    calcularZoomMapa(distancia){
      return Math.max(1, Math.min(Math.round(16 - Math.log2(distancia / 1000)), 18));
    },

    calcularRuta(){
      this.$parent.cargando = true;
      // Se calcula la ruta entre los dos puntos
      const directionsService = new google.maps.DirectionsService(); 
      const request = {
        origin: this.markers.inicio_ruta.position,
        destination: this.markers.fin_ruta.position,
        waypoints: this.markers.waypoints,
        travelMode: 'DRIVING',
      };

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          this.markers.ruta.path = [];
          // Usando los path de los pasos que indica google routes
          result.routes.forEach(route => {
            route.legs.forEach(leg => {
              leg.steps.forEach(stp => {
                const pth_lngth = stp.path.length < 20 ? 5 : stp.path.length > 1000 ? 20 : 10;
                for (let i = 0; i < stp.path.length; i+=pth_lngth) {
                  const element = stp.path[i];
                  this.markers.ruta.path.push({
                    lat: element.lat(),
                    lng: element.lng(),
                  });
                }
              });
            });
          });

          // Se ajusta la posicion de los markers de inicio y fin de la ruta 
          this.markers.inicio_ruta = {
            icon: this.inicio_ruta,
            position: {
              lat: parseFloat(this.markers.ruta.path[0].lat),
              lng: parseFloat(this.markers.ruta.path[0].lng),
            },
          };
          this.markers.fin_ruta = {
            icon: this.fin_ruta,
            position: {
              lat: parseFloat(this.markers.ruta.path[this.markers.ruta.path.length - 1].lat),
              lng: parseFloat(this.markers.ruta.path[this.markers.ruta.path.length - 1].lng),
            },
          };
          this.$parent.cargando = false;
        } else {
          this.$parent.cargando = false;
          this.getMarkersRuta()
          this.$swal({
            icon: "error",
            title: 'No se pudo cargar la ruta: ' + status,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
    },
    // Fin - Funciones para el manejo general del mapa

    // Funciones que vienen del autocomplete de google
    setPlaceOrigen(place) {
      this.markers.inicio_ruta = {
        icon: this.inicio_ruta,
        position: {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        }
      }

      this.markers.ruta.path[0] = {
        lat: parseFloat(place.geometry.location.lat()),
        lng: parseFloat(place.geometry.location.lng()),
      };

      if (this.markers.fin_ruta) {
        // this.calcularRuta();
      }
      // this.centrarMapa();
    },

    setPlaceDestino(place) {
      this.markers.fin_ruta = {
        icon: this.fin_ruta,
        position: {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        }
      }

      if (this.markers.ruta.path.length > 1) {
        this.markers.ruta.path[this.markers.ruta.path.length - 1] = {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        };
      } else {
        this.markers.ruta.path.push({
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        });
      }
      // this.calcularRuta();
      // this.centrarMapa();
    },

    setPlaceWaypoint(place){
      this.markers.waypoints.push({
        location: {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        },
        stopover: true,
      });
      this.calcularRuta();
      this.centrarMapa();
    },
    // Fin - Funciones que vienen del autocomplete de google

    // Funciones que vienen de los campos de coordenadas
    resetCoors(){
      this.coords = {
        lat_origen: null,
        lng_origen: null,
        lat_destino: null,
        lng_destino: null,
      };
    },

    setCoordOrigen() {
      if (this.coords.lat_origen && this.coords.lng_origen) {
        this.markers.inicio_ruta = {
          icon: this.inicio_ruta,
          position: {
            lat: parseFloat(this.coords.lat_origen),
            lng: parseFloat(this.coords.lng_origen),
          }
        }
  
        this.markers.ruta.path[0] = {
          lat: parseFloat(this.coords.lat_origen),
          lng: parseFloat(this.coords.lng_origen),
        };
  
        if (this.markers.fin_ruta) {
          this.calcularRuta();
        }
        this.centrarMapa();
      }
    },

    setCoordDestino() {
      if (this.coords.lat_destino && this.coords.lng_destino) {
        this.markers.fin_ruta = {
          icon: this.fin_ruta,
          position: {
            lat: parseFloat(this.coords.lat_destino),
            lng: parseFloat(this.coords.lng_destino),
          }
        }
  
        if (this.markers.ruta.path.length > 1) {
          this.markers.ruta.path[this.markers.ruta.path.length - 1] = {
            lat: parseFloat(this.coords.lat_destino),
            lng: parseFloat(this.coords.lng_destino),
          };
        } else {
          this.markers.ruta.path.push({
            lat: parseFloat(this.coords.lat_destino),
            lng: parseFloat(this.coords.lng_destino),
          });
        }
        this.calcularRuta();
        this.centrarMapa();
      }
    },
    // Fin - Funciones que vienen de los campos de coordenadas

    // Funciones para los waypoints
    editWaypoint(event, index){
      this.markers.waypoints[index] = {
        location: {
          lat: parseFloat(event.latLng.lat()),
          lng: parseFloat(event.latLng.lng()),
        },
        stopover: true,
      };
      this.calcularRuta();
    },

    removeWaypoint(index) {
      this.markers.waypoints.splice(index, 1);
      this.calcularRuta();
    },

    save() {
      this.$swal({
        title: "Está seguro de editar esta ruta?",
        text: "Los cambios reestablecerán las velocidades máximas que se hayan modificado y deverán ser ajustadas nuevamente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Modificar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          this.form = {
            id: this.ruta.id,
            path: this.markers.ruta.path,
            waypoints: JSON.stringify(this.markers.waypoints),
          }

          axios
            .post("/api/admin/rutas/saveDetalle", this.form)
            .then((response) => {
              this.$parent.cargando = false;
              this.accion_ruta = false;
              this.$parent.getIndex();

              this.$swal({
                icon: "success",
                title: "Se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              this.$parent.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error: " + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    saveMaxVel(){
      this.$parent.cargando = true;
      axios
        .put("/api/admin/rutas/saveDetalleVel", this.form_vel)
        .then((response) => {
          this.$parent.cargando = false;
          this.accion_ruta = false;
          this.$parent.getIndex();
          this.$refs.closeModal.click();

          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
